import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"
import ContactUsSection from "../../../components/contactUsSection"
import Arrow from "../../../icons/arrow.svg"
import ProductDesc from "../../../components/productDesc"
import ProductNotice from "../../../components/ProductNotice"
import { GoToCheckOut } from "../../../components/goToCheckOut"
import { device } from "../../../styles/device"
import { useIntl } from "gatsby-plugin-react-intl"

export default function Product({ data: { product, contentful } }) {
    const intl = useIntl()

    const {
        options,
        variants,
        variants: [initialVariant],
        priceRangeV2,
        title,
        description,
        // images,
        // images: [firstImage],
    } = product
    const { client } = React.useContext(StoreContext)

    const { checkout } = React.useContext(StoreContext)
    const emptyCart = checkout.lineItems.length === 0

    const [variant, setVariant] = React.useState({ ...initialVariant })
    const [quantity, setQuantity] = React.useState(1)

    const productVariant = client.product.helpers.variantForOptions(product, variant) || variant

    const [available, setAvailable] = React.useState(productVariant.availableForSale)

    const checkAvailablity = React.useCallback(
        (productId) => {
            client.product.fetch(productId).then((fetchedProduct) => {
                const result =
                    fetchedProduct?.variants.filter((variant) => variant.id === productVariant.storefrontId) ?? []

                if (result.length > 0) {
                    setAvailable(result[0].available)
                }
            })
        },
        [productVariant.storefrontId, client.product]
    )

    const handleOptionChange = (index, event) => {
        const value = event.target.value

        if (value === "") {
            return
        }

        const currentOptions = [...variant.selectedOptions]

        currentOptions[index] = {
            ...currentOptions[index],
            value,
        }

        const selectedVariant = variants.find((variant) => {
            return isEqual(currentOptions, variant.selectedOptions)
        })

        setVariant({ ...selectedVariant })
    }

    React.useEffect(() => {
        checkAvailablity(product.storefrontId)
    }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

    // const taxIncludedPrice = formatPrice(
    //     priceRangeV2.minVariantPrice.currencyCode,
    //     priceRangeV2.minVariantPrice.amount * 1.1
    // )
    const price = formatPrice(priceRangeV2.minVariantPrice.currencyCode, variant.price)

    const hasVariants = variants.length > 1

    const content = intl.locale === "ja" ? contentful.nodes[0] : contentful.nodes[1]

    return (
        <Layout>
            <Seo title={title} description={description} image={getSrc(content.productImage[0].gatsbyImageData)} />
            <div css={productSection}>
                <ul css={leftContainer}>
                    {/* {images.map((image, index) => (
                        <li key={`product-image-${image.id}`}>
                            <GatsbyImage
                                loading={index === 0 ? "eager" : "lazy"}
                                alt={image.altText ? image.altText : `Product Image of ${title} #${index + 1}`}
                                image={image.gatsbyImageData}
                                css={productImage}
                            />
                        </li>
                    ))} */}
                    {content.productImage.map((image, i) => (
                        <li key={`product-image-key-${i}`}>
                            <GatsbyImage
                                loading={i === 0 ? "eager" : "lazy"}
                                alt={`Product Image - ${i}`}
                                image={image.gatsbyImageData}
                                css={productImage}
                            />
                        </li>
                    ))}
                </ul>
                <div css={rightContainer}>
                    <h4 css={productTitle}>{title}</h4>
                    <h3 css={productPrice}>
                        {price}
                        <span>{intl.formatMessage({ id: "tax_included" })}</span>
                    </h3>
                    <ProductDesc />
                    <p>{intl.formatMessage({ id: "size" })}</p>
                    <fieldset css={productSize}>
                        {hasVariants &&
                            options.map(({ id, name, values }, index) => (
                                <div key={id} css={selectVariant}>
                                    <select
                                        aria-label="Variants"
                                        onChange={(event) => handleOptionChange(index, event)}
                                        css={selectButton}
                                    >
                                        <option value="">Select</option>
                                        {values.map((value) => (
                                            <option value={value} key={`${name}-${value}`}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                    </fieldset>
                    <div>
                        <div css={productNum}>
                            <p>{intl.formatMessage({ id: "quantity" })}</p>
                            <NumericInput
                                aria-label="Quantity"
                                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                                onChange={(event) => setQuantity(event.currentTarget.value)}
                                value={quantity}
                                min="1"
                                max="20"
                            />
                        </div>
                        <AddToCart variantId={productVariant.storefrontId} quantity={quantity} available={available} />
                        {!emptyCart && <GoToCheckOut />}
                    </div>
                    <ProductNotice />
                </div>
            </div>
            <ContactUsSection />
        </Layout>
    )
}

const productSection = css`
    display: flex;
    justify-content: space-between;
    margin: 0 auto 35vmin;
    padding-top: 10vmin;

    @media ${device.sp} {
        display: block;
        width: 90%;
        padding-top: 0;
    }
`
const leftContainer = css`
    width: 53%;
    padding-left: 5%;
    @media ${device.sp} {
        width: 100%;
        padding: 0;
        margin-bottom: 3.5rem;
    }
`
const rightContainer = css`
    width: 28%;
    padding-right: 8%;
    @media ${device.tb} {
        width: 33%;
        padding-right: 5%;
    }
    @media ${device.sp} {
        width: 90%;
        padding: 0;
        margin: 0 auto;
    }
`
const productImage = css`
    width: 100%;
    height: auto;
    margin-bottom: 10px;
`
const productTitle = css`
    margin-bottom: 1rem;
    @media ${device.sp} {
        margin-bottom: 0.5rem;
    }
`
const productPrice = css`
    margin-bottom: 3rem;
    span {
        font-size: 1.4rem;
        padding-left: 1rem;
    }

    @media ${device.sp} {
        margin-bottom: 5rem;
    }
`
const productSize = css`
    border: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 20px;

    @media ${device.sp} {
        display: inline-block;
    }
`
const selectVariant = css`
    background-color: white;
    cursor: pointer;
    width: 200px;
    height: 40px;
    position: relative;
    border: solid 1px black;
    &::after {
        background-image: url(${Arrow});
        background-repeat: no-repeat;
        content: "";
        height: 10px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        pointer-events: none;
    }

    @media ${device.sp} {
        width: 80vw;
        height: 50px;
    }
`
const selectButton = css`
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    height: 40px;
    margin: 0;
    padding: 4px 16px;
    padding-right: 24px;
    width: 100%;

    @media ${device.sp} {
        height: 50px;
    }
`
const productNum = css`
    width: 200px;
    margin-bottom: 4rem;

    @media ${device.sp} {
        width: 80vw;
    }
`

export const query = graphql`
    query ($id: String!) {
        product: shopifyProduct(id: { eq: $id }) {
            title
            description
            productType
            productTypeSlug: gatsbyPath(filePath: "/shop/{ShopifyProduct.productType}")
            tags
            priceRangeV2 {
                maxVariantPrice {
                    amount
                    currencyCode
                }
                minVariantPrice {
                    amount
                    currencyCode
                }
            }
            storefrontId
            images {
                # altText
                id
                gatsbyImageData
            }
            variants {
                availableForSale
                storefrontId
                title
                price
                selectedOptions {
                    name
                    value
                }
            }
            options {
                name
                values
                id
            }
        }
        contentful: allContentfulShopSection {
            nodes {
                productImage {
                    gatsbyImageData
                }
            }
        }
    }
`
